import React, { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import { icons } from "../common/constants/icons.constants";
import { Alert } from '@mui/material';

import "./page.css"
import { DropdownModel } from "../models/Dropdown.model";
import { assets } from "../common/constants/assets.constants";
import { DiscountTimer } from "../components/timer/DiscountTimer";
import { Slider } from "../components/slider/Slider";
import { PhoneViewPort } from "../components/phoeViewPort/PhoneViewPort";
import { FeatureCard } from "../components/feature-card/FeatureCard";
import { presentationImagesData, recommendationCardsData } from "../common/constants/page.constants";
import { RecommendationCard } from "../components/recommendation-card/RecommendationCard";
import { Carousel } from "../components/carousel/Carousel";
import { TermsAndPrivacyModel } from "../models/Terms.model";
import { useTranslation } from 'react-i18next';
import { API } from "../api/api";
import { IApi } from "../api/api.interface";
import 'react-phone-number-input/style.css'
import { Utils } from "../utils";
import { useDecreaseTime } from "../hooks/useDecreaseTime";
const { LanguageIcon, DropdownMenuIcon, BrandWhatsapp } = icons;


const defaultLanguage = 'he';
const api = new API();
const $dateUtils = new Utils.Moment();

export const Page = () => {
    const showDescriptionForPresentation = useMediaQuery({ minWidth: 567 })
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const isLapTop = useMediaQuery({ minWidth: 997 })
    const isDesktop = useMediaQuery({ minWidth: 1201 })

    const [language, setLanguage] = useState<string>(defaultLanguage)
    const [dropdownMenuStatus, setDropdownMenuStatus] = useState<boolean>(false);
    const [phoneViewPortLoading, setPhoneViewPortLoading] = useState<boolean>(false);
    const [presentationImage, setPresentationImage] = useState<number>(0);
    const [openModel, setOpenModel] = useState<boolean>(false);
    const [lead, setLead] = useState<IApi.ILead.Payload>({ fullName: '', phoneNumber: '+972', status: false });
    const [lead2, setLead2] = useState<IApi.ILead.Payload>({ fullName: '', phoneNumber: '+972', status: false });
    const [dates, setDates] = useState<{ startDate: number, endDate: number }>({ startDate: 0, endDate: 0 });
    const { t, i18n } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);


    const presentationLeftSideList = [
        {
            featureName: 'bondi_feature_presentation_translation.features_list_left_side.0.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_left_side.0.featureDescription"
        },
        {
            featureName: 'bondi_feature_presentation_translation.features_list_left_side.1.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_left_side.1.featureDescription"
        },
        {
            featureName: 'bondi_feature_presentation_translation.features_list_left_side.2.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_left_side.2.featureDescription"
        },
        {
            featureName: 'bondi_feature_presentation_translation.features_list_left_side.3.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_left_side.3.featureDescription"
        },
    ]
    const presentationRightSideList = [
        {
            featureName: 'bondi_feature_presentation_translation.features_list_right_side.0.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_right_side.0.featureDescription"
        },
        {
            featureName: 'bondi_feature_presentation_translation.features_list_right_side.1.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_right_side.1.featureDescription"
        },
        {
            featureName: 'bondi_feature_presentation_translation.features_list_right_side.2.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_right_side.2.featureDescription"
        },
        {
            featureName: 'bondi_feature_presentation_translation.features_list_right_side.3.featureName',
            featureDescription: "bondi_feature_presentation_translation.features_list_right_side.3.featureDescription"
        },
    ]


    useEffect(() => {
        if (isSubmitted) {
            const id = setTimeout(() => {
                setIsSubmitted(false);
            }, 10000);

            return () => {
                clearTimeout(id);
            };
        };

    }, [isSubmitted]);

    useEffect(() => {
        async function fetchDiscountGap() {
            const date = await api.fetchDiscountGap();
            setDates(date);
        }

        fetchDiscountGap();
    }, []);

    useEffect(() => {
        const elements = document.querySelectorAll("*");
        elements.forEach(element => element.setAttribute('data-language', language));
    }, [language]);

    useEffect(() => {
        i18n.changeLanguage(language);

        const body = document.getElementsByTagName('body')[0]

        if (body) {
            body.setAttribute('language', language === 'he' ? 'rtl' : 'ltr');
        };
    }, [language])

    const handleLanguageController = () => {

        if (language === 'en') {
            setLanguage('he');
        }
        else {
            setLanguage('en');
        }
    }


    const handlePresentationImage = (index: number) => {
        setPresentationImage(index);
    }



    const handleLink = (link: 'whatsapp') => {
        if (link === 'whatsapp') {
            const message = "Hello I'm interested in yours services";
            window.open(`https://wa.me/972502886610?text=${message}`);
        }
    }


    const [time, setTime] = useDecreaseTime($dateUtils.calcDiff(dates));

    const discountTimesList: string[] = $dateUtils.listDiffObj({ ...time });


    useEffect(() => {
        setTime($dateUtils.calcDiff(dates));
    }, [dates]);

    const handleRenderingCards = (side: 'left' | 'right') => {
        if (side === 'left') {
            return presentationLeftSideList.map(({ featureName, featureDescription }, idx) => {
                let styleObject = {}
                if ((idx === 0 || idx === presentationLeftSideList.length - 1) && !isLapTop) {
                    styleObject = { transform: `translateX(0%) translateX(30%) scale(.8)` }
                }
                return (
                    <>
                        <FeatureCard
                            side={side}
                            StyleObject={styleObject}
                            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                event.stopPropagation();
                                handlePresentationImage(idx)
                                const target = event.target as HTMLElement
                                const currentElement = target.closest('.feature_card') as HTMLDivElement;
                                const currentNestedElement = currentElement.querySelector('.feature_card__content > p') as HTMLParagraphElement;
                                const currentNestedElement2 = currentElement.querySelector('.feature_card__content > h2') as HTMLHeadingElement;
                                const elements = document.querySelectorAll('.feature_card') as NodeListOf<HTMLDivElement>
                                const nestedElements = document.querySelectorAll('.feature_card__content > p') as NodeListOf<HTMLParagraphElement>
                                const nestedElements2 = document.querySelectorAll('.feature_card__content > h2') as NodeListOf<HTMLHeadingElement>

                                elements.forEach(el => {
                                    el.style.backgroundColor = "#ffffff"
                                    el.style.color = "#000000"
                                })

                                nestedElements.forEach(nel => {
                                    nel.style.color = "gray"
                                })
                                nestedElements2.forEach(nel => {
                                    nel.style.color = "black"
                                })

                                if (currentElement && currentNestedElement && currentNestedElement2) {

                                    currentElement.style.backgroundColor = "#6366F1"
                                    currentNestedElement.style.color = "#ffffff"
                                    currentNestedElement2.style.color = "#ffffff"
                                }
                            }} featureName={t(featureName)} featureDescription={!showDescriptionForPresentation ? "" : t(featureDescription)} />
                    </>
                )
            })
        }
        else {
            return presentationRightSideList.map(({ featureName, featureDescription }, idx) => {
                let styleObject: CSSProperties = {}
                if ((idx === 0 || idx === presentationRightSideList.length - 1) && !isLapTop) {
                    styleObject = { transform: `translateX(0%) translateX(-30%) scale(.8)` }
                }
                return (
                    <>
                        <FeatureCard
                            side={side}
                            StyleObject={styleObject}
                            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                event.stopPropagation();
                                handlePresentationImage(idx + Math.floor((presentationImagesData.length) / 2))
                                const target = event.target as HTMLElement
                                const currentElement = target.closest('.feature_card') as HTMLDivElement;
                                const currentNestedElement = currentElement.querySelector('.feature_card__content > p') as HTMLParagraphElement;
                                const currentNestedElement2 = currentElement.querySelector('.feature_card__content > h2') as HTMLHeadingElement;
                                const elements = document.querySelectorAll('.feature_card') as NodeListOf<HTMLDivElement>
                                const nestedElements = document.querySelectorAll('.feature_card__content > p') as NodeListOf<HTMLParagraphElement>
                                const nestedElements2 = document.querySelectorAll('.feature_card__content > h2') as NodeListOf<HTMLHeadingElement>

                                elements.forEach(el => {
                                    el.style.backgroundColor = "#ffffff"
                                    el.style.color = "#000000"
                                })

                                nestedElements.forEach(nel => {
                                    nel.style.color = "gray"
                                })
                                nestedElements2.forEach(nel => {
                                    nel.style.color = "black"
                                })

                                if (currentElement && currentNestedElement && currentNestedElement2) {

                                    currentElement.style.backgroundColor = "#6366F1"
                                    currentNestedElement.style.color = "#ffffff"
                                    currentNestedElement2.style.color = "#ffffff"
                                }

                            }} featureName={t(featureName)} featureDescription={!showDescriptionForPresentation ? "" : t(featureDescription)} />
                    </>
                )
            })
        }
    }

    return (
        <>
            <nav id="navbar">
                <div id="navbar_wrapper_fixed">
                    <div id="navbar__left_side_container">
                        <LanguageIcon
                            id="language_controller"
                            onClick={handleLanguageController}
                        />
                    </div>
                    <div id="navbar__middle_container">
                        {isMobile ?
                            (
                                <>
                                    <DropdownMenuIcon onClick={() => setDropdownMenuStatus(true)} id="dropdown_menu_controller" />
                                    <DropdownModel setStatus={setDropdownMenuStatus} status={dropdownMenuStatus}>
                                        <li><a style={{ display: 'unset', textDecoration: 'none', color: 'inherit' }} href="#contact_whatsapp">{t('navbar_translation.link_text.contactUs')}</a></li>

                                        <li><a style={{ display: 'unset', textDecoration: 'none', color: 'inherit' }} href="#bondi_feature_presentation__title_container">{t('navbar_translation.link_text.features')}</a></li>

                                        <li><a style={{ display: 'unset', textDecoration: 'none', color: 'inherit' }} href="https://www.youtube.com/@bondiapp" target="_blank">{t('navbar_translation.link_text.tutorials')}</a></li>

                                    </DropdownModel>
                                </>
                            ) : (
                                <>
                                    <ul id="navbar__list_items_container">
                                        <li><a style={{ display: 'unset', textDecoration: 'none', color: 'inherit' }} href="#contact_whatsapp">{t('navbar_translation.link_text.contactUs')}</a></li>
                                        <li><a style={{ display: 'unset', textDecoration: 'none', color: 'inherit' }} href="#bondi_feature_presentation__title_container">{t('navbar_translation.link_text.features')}</a></li>
                                        <li><a style={{ display: 'unset', textDecoration: 'none', color: 'inherit' }} href="https://www.youtube.com/@bondiapp" target="_blank">{t('navbar_translation.link_text.tutorials')}</a></li>
                                    </ul>
                                </>
                            )
                        }
                    </div>
                    <div id="navbar__right_side_container">
                        <button style={{ cursor: "pointer" }} id='navbar-button-book-demo'><a style={{ all: 'unset' }} href="#join_bondi_form__title_container">{t('navbar_translation.button_text.bookDemo')}</a></button>
                        <button style={{ cursor: "pointer" }} id='navbar-button-sign-in'><a style={{ all: "unset" }} href="https://app.bondiservice.com/">{t('navbar_translation.button_text.signIn')}</a></button>
                    </div>
                </div>
            </nav>
            {/* ------------------------------END OF A FEATURE------------------------------------ */}

            <main id="main_content_page">
                {/* DEFINE THE EDGES FOR MOBILE AND DESKTOP/TABLET */}

                <div id="welcome_section section__1">
                    {/* DEFINE THE WRAPPER OF THE WELCOME SECTION " SECTION 1 ! " */}
                    <div id="welcome_section__hero">
                        {/* CONTAIN THE HERO OF THE WELCOME SECTION FOR MOBILE AND DESKTOP/TABLET*/}
                    </div>
                    <header id="welcome_section__header">
                        <div id="welcome_section__header__logo_container">
                            <img src={assets.Logo} alt="BONDI" />
                        </div>
                        <div id="welcome_section__header__count_down_component_wrapper">
                            <DiscountTimer list={discountTimesList} />
                        </div>
                        <div id="welcome_section__header__headline">
                            {/* INCLUDING TRANSLATIONS */}
                            <h1>
                                {t('welcome_section_translation.headlines_text.title')}
                            </h1>
                            <h2>
                                {t('welcome_section_translation.headlines_text.subTitle')}
                            </h2>
                        </div>
                        <div id="welcome_section__header__product_headline_description">
                            <p>
                                {t('welcome_section_translation.headlines_text.description')}
                            </p>
                        </div>
                        <main id="welcome_section__header__main_content">
                            {/* TRANSLATION INCLUDED */}
                            <div id="welcome_section__header__main_content_minimized_lead_form" className="content_container" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                                <div id="welcome_section__header__main_content_minimized_lead_form__full_name_box">
                                    <label>
                                        {/* FULL NAME LABEL */}
                                        {t('welcome_section_translation.minimized_lead_form_text.field_text.fullNameLabel')}
                                    </label>
                                    <input className="text_input" value={lead.fullName} onChange={({ target: { value } }) => {
                                        setLead({
                                            ...lead,
                                            fullName: value,
                                        })
                                    }} type="text" placeholder={t('welcome_section_translation.minimized_lead_form_text.field_text.fullNameInputPlaceholder')} />
                                </div>
                                <div id="welcome_section__header__main_content_minimized_lead_form__phone_number_box">
                                    <label>
                                        {/* PHONE NUMBER LABEL */}
                                        {t('welcome_section_translation.minimized_lead_form_text.field_text.phoneNumberLabel')}
                                    </label>
                                    <input className="number_input" value={lead.phoneNumber} onChange={({ target: { value } }) => {
                                        const onlyNumbers = new RegExp('^[0-9]*$')
                                        if (!value.startsWith('+972')) {
                                            setLead({
                                                ...lead,
                                                phoneNumber: '+972',
                                            })
                                        }
                                        else {
                                            if (onlyNumbers.test(value.slice(1))) {
                                                setLead({
                                                    ...lead,
                                                    phoneNumber: value,
                                                })
                                            }
                                        }
                                    }} type="text" placeholder={t('welcome_section_translation.minimized_lead_form_text.field_text.phoneNumberInputPlaceholder')} />
                                </div>
                                <div id="welcome_section__header__main_content_minimized_lead_form__request_box">
                                    <button
                                        onClick={async () => {
                                            const response = await api.postNewLead(lead);
                                            if (response !== undefined) {
                                                setIsSubmitted(true)
                                                setLead({
                                                    fullName: '',
                                                    phoneNumber: '+972',
                                                    status: false,
                                                });
                                            }
                                        }}
                                    >{t('welcome_section_translation.minimized_lead_form_text.button_text.requestCall')}</button>
                                </div>
                            </div>
                            <div id="welcome_section__header__main_content__wrapper_slider_component">
                                {/* WELCOME SECTION HEADER IN THE MAIN CONTENT IMAGES SLIDER FOR MOBILE AND DESKTOP/TABLET COMPONENT*/}
                                <Slider slides={assets.ProductsSliders} />
                            </div>
                        </main>
                    </header>
                </div>

                {/* -------------------------END OF A FEATURE---------------------------------- */}

                <div id="discount__post">
                    {/* DEFINE THE SECTION OF THE POST */}
                    <div id="discount_post__wrapper">
                        {/* DEFINE THE EDGES OF THE POST */}
                        <div id="discount_post__hero">
                            {/* DISCOUNT POST FOR LINKING REMINDER OF LEAD REQUEST FOR MOBILE AND DESKTOP/TABLET */}
                            <img src={assets.DiscountPost} alt='Discount Post' />
                        </div>
                        <main id="discount_post__content">
                            <header className="content_container" id="discount_post__content__headline">
                                <h1>
                                    {/* FIRST HEADLINE OF THE POST CONTENT */}
                                    {t('discount_post_translation.headlines_text.title')}
                                </h1>
                            </header>
                            <div className="content_container" id="discount_post__content__description">
                                <p>
                                    {/* DESCRIPTION FOR THE POST DISCOUNT CONTENT  */}
                                    {t('discount_post_translation.headlines_text.description')}
                                </p>
                            </div>
                            <div id="discount_post__content__countdown_discount">
                                <DiscountTimer list={discountTimesList} />
                            </div>
                            <div id="discount_post__content__like_discount">
                                <button>
                                    {/* LINKING BUTTON FOT THR LEAD ENGAGEMENT FORM SECTION */}
                                    <a href="#join_bondi_form__title_container" style={{
                                        all: 'unset',
                                    }}>
                                        {t('discount_post_translation.button_text.joinUs')}
                                    </a>
                                </button>
                            </div>
                        </main>
                    </div>

                </div>

                {/* -------------------------END OF A FEATURE---------------------------------- */}
                <div id="bondi_feature_presentation__title_container">
                    <h1 dangerouslySetInnerHTML={{ __html: t('bondi_feature_presentation_translation.headlines_text.title') }} />
                    <p>{isMobile ? t('bondi_feature_presentation_translation.headlines_text.subTitle.mobile') : t('bondi_feature_presentation_translation.headlines_text.subTitle.default')}</p>
                </div>
                <div id="bondi_feature_presentation">
                    <div className="bondi_feature_presentation__list_container">
                        <ul className="bondi_feature_presentation__list">
                            {handleRenderingCards('left')}
                        </ul>
                    </div>
                    <div id="bondi_feature_presentation__smartphone_view_port_component">
                        <div id="bondi_feature_presentation__smart_phone_view_port_component_background"><img src={assets.Circle} alt="" /></div>
                        <PhoneViewPort>
                            <div id="bondi_feature_presentation__smartphone_view_port_component_children_content" data-isLoading={phoneViewPortLoading}>
                                {phoneViewPortLoading ?
                                    (
                                        <>
                                            <img src={assets.LogoNoText} alt="" />
                                        </>
                                    ) : (
                                        <>
                                            <img style={{ width: '100%' }} src={presentationImagesData[presentationImage].url} alt="" />
                                        </>
                                    )}
                            </div>
                        </PhoneViewPort>

                    </div>
                    <div className="bondi_feature_presentation__list_container">
                        <ul className="bondi_feature_presentation__list">
                            {handleRenderingCards('right')}
                        </ul>
                    </div>
                </div>

                <div id="join_bondi_form__title_container">
                    <h1 dangerouslySetInnerHTML={{ __html: t('join_bondi_form.headlines_text.title') }} />
                </div>
                <>
                    {!isDesktop && <Carousel list={recommendationCardsData} callback={(params: {
                        name: string;
                        subject: string;
                        description: string;
                        date: string;
                    }, idx) => <RecommendationCard key={idx} date={params.date} name={t(params.name)} description={t(params.description)} subject={t(params.subject)} animate={true} />} />}
                </>
                <div id="join_bondi_form">
                    {
                        isDesktop ?
                            (
                                <div className="join_bondi_form__recommendations_list_container">
                                    <ul className="join_bondi_form__recommendations_list content_container" data-side={'left'}>
                                        <RecommendationCard name={t('recommendations.2.name')} subject={(t('recommendations.2.subject'))} description={(t('recommendations.2.description'))} date="12.18 AM" />
                                        <RecommendationCard name={t('recommendations.3.name')} subject={(t('recommendations.3.subject'))} description={(t('recommendations.3.description'))} date="12.18 AM" />
                                    </ul>
                                </div>
                            )
                            : null}

                    <div id="join_bondi_form__form_container">
                        {/* TRANSLATION IS INCLUDED */}
                        <div id="join_bondi_form__form" className="content_container">
                            <div className="join_bondi_form__form__label_container">
                                <label>{t("welcome_section_translation.minimized_lead_form_text.field_text.fullNameLabel")}</label>
                                <input className="text_input" value={lead2.fullName} onChange={({ target: { value } }) => {
                                    setLead2({
                                        ...lead2,
                                        fullName: value,
                                    })
                                }} placeholder={t("welcome_section_translation.minimized_lead_form_text.field_text.fullNameInputPlaceholder")} type="text" />
                            </div>
                            <div className="join_bondi_form__form__label_container">
                                <label>{t("welcome_section_translation.minimized_lead_form_text.field_text.phoneNumberLabel")}</label>
                                <input className="number_input" value={lead2.phoneNumber} onChange={({ target: { value } }) => {
                                    const onlyNumbers = new RegExp('^[0-9]*$')
                                    if (!value.startsWith('+972')) {
                                        setLead2({
                                            ...lead2,
                                            phoneNumber: '+972',
                                        })
                                    }
                                    else {
                                        if (onlyNumbers.test(value.slice(1))) {
                                            setLead2({
                                                ...lead2,
                                                phoneNumber: value,
                                            })
                                        }
                                    }
                                }} placeholder={t("welcome_section_translation.minimized_lead_form_text.field_text.phoneNumberInputPlaceholder")} type="text" />
                            </div>
                            <div id="welcome_section__header__main_content_minimized_lead_form__request_box">
                                <button
                                    style={{ marginTop: '.8vw' }}
                                    onClick={async () => {
                                        const response = await api.postNewLead(lead2);
                                        if (response !== undefined) {
                                            setIsSubmitted(true)
                                            setLead2({
                                                fullName: '',
                                                phoneNumber: '+972',
                                                status: false,
                                            })
                                        }
                                    }}
                                >{t('welcome_section_translation.minimized_lead_form_text.button_text.requestCall')}</button>
                            </div>
                        </div>
                    </div>
                    {
                        isDesktop ?
                            (
                                <div className="join_bondi_form__recommendations_list_container">
                                    <ul className="join_bondi_form__recommendations_list content_container" data-side={'right'}>
                                        <RecommendationCard name={t('recommendations.0.name')} subject={(t('recommendations.0.subject'))} description={(t('recommendations.0.description'))} date="12.18 AM" />
                                        <RecommendationCard name={t('recommendations.1.name')} subject={(t('recommendations.1.subject'))} description={(t('recommendations.1.description'))} date="12.18 AM" />
                                    </ul>
                                </div>
                            )
                            : null}
                </div>
                <div id="contact_whatsapp">
                    {isDesktop && (

                        <div id="contact_whatsapp__phone_view_port_wrapper">
                            <PhoneViewPort>
                                <div id="contact_whatsapp__container">
                                    <img id="contact_whatsapp__container__image" src={assets.ContactPhoneView} />
                                </div>
                            </PhoneViewPort>
                        </div>

                    )}
                    <div id="contact_whatsapp__content">
                        <div id="contact_whatsapp__content__header_wrapper">
                            <h1>{t("questions.title")}</h1>
                            <p>{t("questions.description")}</p>
                        </div>
                        <div id="contact_whatsapp__content__contact_button_container">
                            <button style={{ cursor: 'pointer' }} onClick={() => handleLink('whatsapp')} ><BrandWhatsapp size={42} /> <span>+972 50 288 6610</span></button>
                        </div>

                    </div>
                </div>
            </main>
            <>
                {
                    openModel && (
                        <div onClick={() => setOpenModel(false)} style={{
                            position: 'fixed',
                            top: 0,
                            zIndex: 9998,
                            backgroundColor: 'black',
                            opacity: 0.7,
                            width: "100vw",
                            height: '100vh'
                        }}>
                        </div>
                    )
                }
                <TermsAndPrivacyModel show={openModel} />
                <footer id="footer">
                    <div id="footer-content-wrapper">
                        <div className="footer-content-container">
                            <span>Copyright © 2022. Bondi.</span>
                        </div>
                        <div className="footer-content-container">
                            <span style={{ cursor: "pointer" }} onClick={() => setOpenModel(true)}>Terms & Privacy</span>
                            <span>Cookies</span>
                        </div>
                    </div>

                </footer>
                {isSubmitted && <div onClick={(event) => event.stopPropagation()} style={{ width: '100vw', height: "100vh", position: 'fixed', bottom: 0, left: 0, zIndex: 1, background: 'transparent' }}>
                    <Alert style={{ position: 'absolute', width: '100vw', bottom: 0 }} severity="success" >request a call is submitted successfully</Alert>
                </div>}
            </>

        </>
    )
}