import React from 'react';
import "./terms.model.css";

interface ITermsAndPrivacyProps {
    show: boolean;
}

export const TermsAndPrivacyModel: React.FC<ITermsAndPrivacyProps> = ({ show }) => {

    if (show === false) return null;

    return (
        <div
            id='terms-and-privacy-model'
            className='"modal-body tc-body'
        >
            <div className="section">
                <p className="section-title">
                    1. כללי
                </p>
                <p className="paragraph">
                    1.1 .הגלישה באתר ו/או השימוש ביישומון כפופים לתנאים המפורטים בתקנון ותנאי
                    שימוש אלו )״התקנון״(. אנא קרא את התקנון בקפידה, שכן השימוש באתר ו/או
                    ביישומון וביצוע פעולות בהם מעידים על הסכמתך לתנאים הכלולים בתקנון. כמו כן,
                    Bondi שומרת את זכותה לשנות את תנאי התקנון, מעת לעת, על פי שיקול
                    דעתה הבלעדי וללא הודעה מוקדמת. מועד החלת השינוי כאמור יהיה מרגע
                    פרסומו באתר ו/או ביישומון.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    2.1 .האמור בתקנון זה
                </p>
                <p className="paragraph">
                    מתייחס באופן שווה לבני שני המינים והשימוש בלשון זכר או
                    נקבה הוא מטעמי נוחות בלבד.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    3.1 .תנאי התקנון
                </p>
                <p className="paragraph">
                    חלים על שימוש ב- Bondi ובתכנים הכלולים בה באמצעות כל
                    מחשב או מכשיר תקשורת אחר )כדוגמת טלפון סלולרי, מחשבי טאבלט וכיו"ב(. כמו
                    כן הם חלים על השימוש באתר ו/או ביישומון, בין באמצעות רשת האינטרנט ובין
                    באמצעות כל רשת או אמצעי תקשורת אחרים.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    4.1 .הגלישה באתר
                </p>
                <p className="paragraph">
                    מותרת בכל גיל. פעולה של קטין מתחת לגיל 18
                    מחייבת אישור הורה או אפוטרופוס.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    5.1 .במקרה שייקבע
                </p>
                <p className="paragraph">
                    כי הוראה בתקנון זה אינה ניתנת לאכיפה או שהינה חסרת תוקף
                    מטעם כלשהו, לא יהא בכך כדי להשפיע או לפגוע בחוקיותן, תקפותן ואכיפתן של
                    שאר הוראות התקנון.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    6.1 .Bondi
                </p>
                <p className="paragraph">
                    אינה מתחייבת לקיומו ו/או הפעלתו של האתר ו/או היישומון. יובהר
                    כי האמור בתקנון זה לא יהווה כל התחייבות מצד Bondi להפעלת האתר
                    ו/או היישומון ולמשתמש לא תהא כל טענה על כך.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    2 .השימוש ב- Bondi :
                </p>
                <p className="paragraph">
                    1.2 .השימוש ב- Bondi דורש הרשמה.
                    2.2 .בעת הרשמה ורכישת שירותים באתר ו/או ביישומון יתבקש הגולש למסור פרטים
                    אישיים כגון: שם פרטי, שם משפחה, טלפון וכן כתובת דואר אלקטרוני פעילה
                    )לשיקול דעתו של האתר(. מסירת פרטים חלקיים או שגויים עלולים למנוע את
                    האפשרות להשתמש בשירות ולסכל יצירת קשר במקרה הצורך. במקרה של שינוי
                    פרטים יש לעדכנם באתר ו/או ביישומון.
                    3.2 .מובהר כי אין חובה על-פי חוק למסור את המידע, אולם בלא למוסרו לא ניתן יהיה
                    להשתמש בשירותי האתר ו/או היישומון.
                    4.2 .Bondi  לא תעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות הפרטיות
                    של האתר והיישומון המהווה חלק בלתי נפרד מתקנון זה.
                    5.2 .הרשמה לאתר ו/או ליישומון, בכפוף להסכמת הגולש, כוללת, בין היתר, קבלת תוכן
                    שיווקי, מידע ביחס למבצעים, עדכונים והנחות המוצעים למשתמשים רשומים.
                    6.2 .Bondi  רשאית לקבוע, מעת לעת, דרכי זיהוי לכניסה לאתר ו/או ליישומון
                    ובכלל זה התחברות לאתר ו/או ליישומון דרך הפייסבוק ו/או רשת חברתית אחרת
                    ו/או פלטפורמה אחרת.
                    7.2 .Bondi  רשאית למנוע מכל גולש שימוש באתר ו/או ביישומון לפי שיקול
                    דעתה המוחלט. מבלי לגרוע מהאמור לעיל, Bondi רשאית לחסום גישתו אל
                    האתר ו/או היישומון בכל אחד מהמקרים הבאים:
                    1.7.2 .אם בעת הרשמה לאתר ו/או ליישומון נמסרו במתכוון פרטים שגויים;
                    2.7.2 .במקרה שנעשה שימוש באתר ו/או ביישומון לביצוע או כדי לנסות לבצע מעשה
                    בלתי חוקי על-פי דיני מדינת ישראל, או מעשה הנחזה על פניו כבלתי חוקי
                    כאמור, או כדי לאפשר, להקל, לסייע או לעודד ביצועו של מעשה כזה;
                    3.7.2 .אם הופרו תנאי תקנון זה;
                    4.7.2 .במקרה שנעשה שימוש באתר ו/או ביישומון בניסיון להתחרות באתר ו/או
                    ביישומון;
                    5.7.2 .אם נעשתה על ידי גולש כל פעולה שתמנע מאחרים לגלוש ולהשתמש באתר
                    ו/או ביישומון בכל דרך שהיא.
                </p>
            </div>
            <div className="section">
                <p className="section-title">
                    3 .השירותים המוצעים באתר:
                </p>
                <p className="paragraph">
                    1.3 .השירותים המוצעים על ידי Bondi יופיעו ויוצגו בדפי האתר ו/או היישומון.
                </p>
                <p className="paragraph">
                    2.3 .Bondi  שומרת לעצמה את הזכות לשנות את מגוון השירותים או להפסיקם
                    או לשנות את מחיר השירותים המוצעים באתר ו/או ביישומון ומחוצה להם, ללא
                    הודעה מוקדמת.
                </p>
                <p className="paragraph">
                    3.3 .Bondi  אינה מחויבת, בכל דרך שהיא, לקיים מגוון כלשהו של שירותים.
                </p>
                <p className="paragraph">
                    4.3 .אופן הצגת השירותים באתר ו/או ביישומון הינו על-פי שיקול דעתה הבלעדי של
                    .Bondi
                </p>
                <p className="paragraph">
                    5.3 .בהרשמה לאתר ו/או ליישומון הנך מסכים לשמור את סיסמתך בסודיות, והנך מאשר
                    כי: )1 )חשבונך הוא אישי; )2 )אתה תהיה האחראי הבלעדי לכל פעולה או שימוש
                    שיעשו מחשבונך; )3 )לא תאפשר לאחר גישה לחשבונך.
                </p>
                <p className="paragraph">
                    6.3 .Bondi  שומרת את זכותה לשנות את שם המשתמש שבחרת או להסיר אותו
                    במידה וייקבע כי שם המשתמש אינו הולם או מגונה, לשיקול דעתה הבלעדי של
                    .Bondi
                </p>
                <p className="paragraph">
                    7.3 .מתן השירות יתאפשר בכפוף לשיקול דעתה הבלעדי של Bondi והיא לא
                    תהא אחראית לכל איחור ו/או עיכוב במתן השירות ו/או אי-מתן השירות, כתוצאה
                    מכוח עליון ו/או תקלות טכניות ו/או מאירועים שאינם בשליטתה, לרבות תקלות
                    טכניות או אחרות אצל צד שלישי, לרבות תקלות באפליקציית WhatsApp.
                </p>
                <p className="paragraph">
                    8.3 .יובהר, כי במתן השירות, Bondi מסתמכת על נתונים ומידע הנמסר
                    מהמשתמש וכל תוכן שיימסר על ידי המשתמש ייעשה באחריותו הבלעדית של
                    המשתמש, ועל כן, מובן של- Bondi אין כל אחריות בגין מידע מעין זה,
                    השימוש בו, הפצתו ומקורו ואין היא ערבה למידת הדיוק של המידע והתכנים.
                </p>
                <p className="paragraph">
                    9.3 .Bondi  לא תהיה אחראית לכל נזק )ישיר או עקיף(, הפסד, עגמת נפש
                    והוצאות שייגרמו לגולשים ו/או לרוכשים ו/או לנרשמים באתר ו/או ביישומון ו/או
                    לצדדים שלישיים כלשהם בעקבות מתן השירותים ו/או בגין שליחת הודעות ו/או
                    מסרים עבור המשמתש לרשימות התפוצה שיסופקו על ידו ואלו יהיו באחריות
                    הרוכש בלבד.
                </p>
                <p className="paragraph">
                    10.3 .Bondi  רשאית, אך אינה חייבת, לסרב לפרסם ו/או לשלוח כל תוכן בשם
                    המשתמש על פי שיקול דעתה הבלעדי וללא צורך במתן הודעה מוקדמת או אישור
                    המשתמש לשם כך.
                </p>
                <p className="paragraph">
                    11.3 .Bondi  שומרת לעצמה את הזכות לשנות את מחיר השירותים המוצעים, ללא
                    הודעה מוקדמת.
                </p>
                <p className="paragraph">
                    12.3 .בנוסף להוראות התקנון יחולו גם הוראות תקנון חברת הסליקה או אמצעי התשלום
                    באמצעותו בוצעה העסקה. על אף האמור לעיל, Bondi שומרת לעצמה את
                    הזכות לקבוע הסדרי תשלומים אחרים לנרשמים, והכל על-פי שיקול דעתה הבלעדי.
                </p>
                <p className="paragraph">
                    13.3 .רכישה תבוצע בכפוף לאישור העסקה על ידי חברת האשראי. במקרה בו לא אושרה
                    העסקה על ידי חברת האשראי, המזמין יקבל הודעה וחשבונו לא יחויב בגין
                    העסקה.
                </p>
            </div>
            <div className="section">
                <p className="section-title">
                    1. כללים והגדרות:
                </p>
                <p className="paragraph">
                    1.1 .הגדרות: בתקנון זה, המונחים המופיעים בראשי תיבות מזהים
                    במשמע שלהם בעניין, למעט אם נקבע במפורש אחרת. הכותבת היא המפעילה
                    של אתר Bondi.
                </p>
                <p className="paragraph">
                    2.1 .מטרת תקנון זה היא לספק הגנה לגולשי האתר ולמשתמשי היישומון
                    ולהגן על זכויותיהם ועל נתוניהם האישיים, וכן לסדר את היחסים בין הכותבת
                    לבין הגולשים ו/או המשתמשים ו/או כל גוף אחר שיש לו כל קשר כלשהו
                    לתכנים המפורסמים באמצעות האתר ו/או היישומון.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    2. .שימוש והרשמה:
                </p>
                <p className="paragraph">
                    1.2 .השימוש ב- Bondi דורש הרשמה.
                </p>
                <p className="paragraph">
                    2.2 .בעת הרשמה ורכישת שירותים באתר ו/או ביישומון יתבקש הגולש למסור פרטים
                    אישיים כגון: שם פרטי, שם משפחה, טלפון וכן כתובת דואר אלקטרוני פעילה
                    )לשיקול דעתו של האתר(. מסירת פרטים חלקיים או שגויים עלולים למנוע את
                    האפשרות להשתמש בשירות ולסכל יצירת קשר במקרה הצורך. במקרה של שינוי
                    פרטים יש לעדכנם באתר ו/או ביישומון.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    3. .השירותים המוצעים באתר:
                </p>
                <p className="paragraph">
                    1.3 .השירותים המוצעים על ידי Bondi יופיעו ויוצגו בדפי האתר ו/או היישומון.
                </p>
                <p className="paragraph">
                    2.3 .Bondi  שומרת לעצמה את הזכות לשנות את מגוון השירותים או להפסיקם
                    או לשנות את מחיר השירותים המוצעים באתר ו/או ביישומון ומחוצה להם, ללא
                    הודעה מוקדמת.
                </p>
            </div>

            <div className="section">
                <p className="section-title">
                    4 .מדיניות פרסום תכנים באמצעות השירות:
                </p>
                <p className="paragraph">
                    1.4 .בעת שליחת תכנים באמצעות שירותיה של Bondi חלה על הרוכש האחריות
                    המלאה והבלעדית לכל תוצאה שתנבע משליחת התכנים ועליו להקפיד שתכנים
                    כאלה יהיו חוקיים. בין השאר ולמען הדוגמה בלבד, אין לשלוח את התכנים הבאים:
                </p>
                <p className="paragraph">
                    5.4 .במסירת התכנים לפרסום ושליחה מוקנה ל- Bondi רישיון חינם, כלל עולמי
                    ובלתי מוגבל בזמן, להעתיק, לשכפל, להפיץ, לשווק, למסור לציבור, לעבד, לערוך,
                    לתרגם ולהשתמש בתכנים כאמור בכל דרך נוספת, לפי שיקול דעתה, באתר,
                    ביישומון, בדברי דפוס, לרבות בעיתונים, מגזינים, כתבי עת, ספרים וכיו"ב, במדיות
                    דיגיטליות, לרבות באתרי אינטרנט נוספים, בארכיבים דיגיטאליים ובשירותים
                    הניתנים ברשתות תקשורת, לרבות תקשורת חוטית, אל חוטית, סלולארית, כבלים
                    ולווין.
                </p>
            </div>
            <div className="section">
                <h2>מדיניות שינויים וביטולים</h2>
                <p className="paragraph">
                    5 .מדיניות שינויים וביטולים:
                </p>
                <p className="paragraph">
                    1.5 .בקשה לשינוי או ביטול שירות תועבר ל- Bondi באחד מאמצעי ההתקשרות המופיעים בתחתית התקנון.
                </p>
                <p className="paragraph">
                    2.5 .שינוי ו/או ביטול הזמנת שירות וקבלת החזר כספי יתאפשרו בתוך 14 ימים מביצוע העסקה או ממועד קבלת פרטי העסקה )לפי המאוחר( ובהתאם ללוחות הזמנים הבאים:
                </p>
                <p className="paragraph">
                    1.2.5 .טרם השימוש ב- Bondi ,בהחזר כספי מלא;
                </p>
                <p className="paragraph">
                    2.2.5 .לאחר השימוש ב- Bondi ,לא יינתן החזר כספי כלל.
                </p>
                <p className="paragraph">
                    3.5 .ההחזר הכספי יעשה באמצעי התשלום שבו ביצע הלקוח את התשלום.
                </p>
                <p className="paragraph">
                    4.5 .שינוי או ביטול יאושרו לאחר שהלקוח יקבל הודעה מ- Bondi המאשרת זאת.
                </p>
                <p className="paragraph">
                    5.5 .האמור בסעיף 5 לעיל כפוף להוראות ביטול עסקה בהתאם לחוק הגנת הצרכן, התשמ"א-1981.
                </p>
            </div>
            <div className="section">
                <h2>Bondi אחריות</h2>
                <p className="paragraph">
                    1.6 .אין לראות במידע המופיע באתר ו/או ביישומון משום הבטחה לתוצאה כלשהי ו/או אחריות לאופן הפעילויות של השירותים המוצעים בהם. Bondi לא תהיה אחראית לשום נזק, ישיר או עקיף, אשר ייגרם לגולש כתוצאה מהסתמכות על מידע המופיע באתר ו/או ביישומון ו/או בקישורים לאתרים אחרים ו/או כל מקור מידע פנימי ו/או חיצוני אחר ו/או שימוש בשירותים אשר מוצגים על ידו.
                </p>
                <p className="paragraph">
                    2.6 .מידע ומצגים אודות שירותים המוצגים באתר ו/או ביישומון, שמקורו בשותפיה העסקיים של Bondi ששירותיהם מופיעים באתר ו/או ביישומון )ככל ויופיעו( וכל תוכן ביחס לשירותים נמצאים באחריותם הבלעדית של השותפים העסקיים כאמור, ועל כן, מובן של- Bondi אין כל אחריות בגין מידע מעין זה, ואינה ערבה למידת הדיוק של מידע זה.
                </p>
                <p className="paragraph">
                    3.6 .השירותים המופיעים באתר ו/או ביישומון ניתנים לשימוש באמצעות תכנת צד שלישי, כגון WhatsApp ,לקוחות האתר ו/או היישומון מצהירים ומאשרים כי הם מתחייבים לפעול עפ"י תקנון הצד השלישי )לרבות Whatsapp )וכי אין ל- Bondi כל אחריות לעניין: )1 )חוקיות השימוש Bondi ב- 2; (Whatsapp )השבתת פעילות הלקוח בתכנת הצד השלישי ובגין כל נזק )ישיר או עקיף(, הוצאה ו/או הפסד שנגרם ללקוח בגין כך; )3 )התכנים שישלח המשתמש באמצעות שימוש ב- Bondi .
                </p>
                <p className="paragraph">
                    4.6 .כמו כן, לקוחות האתר ו/או היישומון מתחייבים כי יפעלו בהתאם להוראות חוקי התקשורת )ספאם( והפרסום הרלוונטיות במדינה בה הם משתמשים בשירות ובמדינת היעד בה נמצאים אנשי הקשר.
                </p>
                <p className="paragraph">
                    5.6 .Bondi  לא תהיה אחראית לכל נזק )ישיר או עקיף(, הפסד, עגמת נפש והוצאות שייגרמו לגולשים ו/או למשאיר פרטים באתר ו/או ביישומון ו/או לצדדים שלישיים כלשהם בעקבות שימוש או הסתמכות על כל תוכן, מידע, נתון, מצג, תמונה, וידאו, אודיו, פרסומת, מוצר, שירות וכו' המופעים באתר ו/או ביישומון. כל הסתמכות כאמור נעשית על-פי שיקול דעתו ואחריותו הבלעדית של הגולש.
                </p>
                <p className="paragraph">
                    6.6 .Bondi  בשום מקרה לא תהיה אחראית לנזק שנגרם לגולש האתר ו/או היישומון באמצעות יצירת קשר עם שותפיה העסקיים.
                </p>
                <p className="paragraph">
                    7.6 .Bondi  ממליצה למשתמשים לנהוג בזהירות, ולקרוא בעיון את המידע המוצג באתר ו/או ביישומון ובכלל זה את המידע ביחס לשירות עצמו, תיאורו והתאמתו, כמתואר להלן.
                </p>
                <p className="paragraph">
                    8.6 .התכנים באתר ו/או ביישומון ניתנים לשימוש כמות שהם )IS AS ,)ולא ניתן להתאימם לצרכיו של כל אדם ואדם. לא תהיה לגולש כל טענה, תביעה או דרישה כלפי Bondi בגין תכונות של התכנים, יכולותיהם, מגבלותיהם ו/או התאמתם לצרכיו והשימוש באתר ו/או ביישומון יהיה על אחריותו הבלעדית של הגולש באתר ו/או ביישומון.
                </p>
                <p className="paragraph">
                    9.6 .האתר ו/או היישומון בכללותם, לרבות כל המידע המופיע בהם, מוצעים לציבור כמות שהם, ויהיו מדויקים ונכונים ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע.
                </p>
                <p className="paragraph">
                    10.6 .השימוש באתר ו/או ביישומון ייעשה על אחריותו הבלעדית והמלאה של כל גולש. WAPILAND אינה מתחייבת כי תכנים ושירותים המתפרסמים באתר ו/או ביישומון, יהיו מלאים, נכונים, חוקיים או מדויקים או יהלמו את ציפיותיו ודרישותיו של כל גולש. Bondi לא תישא באחריות כלשהי לכל תוצאה שתנבע מהם, או משימוש בהם, או מהסתמכות עליהם, לרבות: )1 )שגיאות, טעויות ואי-דיוקים; )2) נזק לגוף או לרכוש, מכל סוג, הנגרם עקב השימוש באתר ו/או בשירותי האתר ו/או היישומון; )3) הפרעה בגישה לאתר ו/או ליישומון או מהאתר ו/או מהיישומון; )4) כל באג, וירוס, סוסי טרויאני וכיו"ב שעלולים להיות מועברים לאתר ו/או ליישומון על ידי צד שלישי כלשהו.
                </p>
            </div>
            <div className="section">
                <h2>חנויות היישומונים אנדרואיד ואפל</h2>
                <p className="paragraph">
                    1.7 .התנאים הבאים נוגעים לשימוש ביישומון שהורדה דרך חנות גוגל פליי או חנות אפל )״חנויות היישומונים״(:
                </p>
                <p className="paragraph">
                    2.7 .רישיון השימוש ביישומון מוגבל ובלתי ניתן להעברה במכשיר בו הנך משתמש וכפוף לתנאי השימוש של חנויות היישומונים.
                </p>
                <p className="paragraph">
                    3.7 .אחריות Bondi לתחזוקה ותמיכה ביישומון תהיה כאמור בתקנון זה.
                </p>
                <p className="paragraph">
                    4.7 .הנך מצהיר ומסכים כי: )1 )אינך עושה שימוש ביישומון במדינה הכפופה לאמברגו של ממשלת ארה״ב או מדינה המוגדרת על ידי ממשלת ארה״ב כמדינת ״תומכת טרור״; )2 )אינך נמצא ברשימה אסורה או מוגבלת של ממשלת ארה״ב.
                </p>
                <p className="paragraph">
                    5.7 .בעת שימוש ביישומון, הנך מתחייב לעמוד בהוראות חנויות היישומונים וצד שלישי.
                </p>
                <p className="paragraph">
                    6.7 .הנך מסכים ומאשר כי חנויות היישומונים מוטבות כצד שלישי לתנאי תקנון זה ורישיון השימוש ביישומון וכי לכל חנות יישומונים תהיה הזכות לאכוף את תנאי תקנון זה.
                </p>
                <p className="paragraph">
                    7.7 .Bondi  רשאית בכל עת לגבות תשלום בעבור הורדת היישומון ו/או השימוש ביישומון.
                </p>
            </div>
            <div className="section">
                <h2>השימוש ב-Bondi</h2>
                <p className="paragraph">
                    1.8 .השימוש באתר ו/או ביישומון מותר למטרות פרטיות ואישיות בלבד. אין להעתיק ולהשתמש או לאפשר לאחרים להשתמש, בכל דרך אחרת בתכנים מתוך האתר ו/או היישומון, לרבות באתרי אינטרנט אחרים, בפרסומים אלקטרוניים, בפרסומי דפוס וכיו"ב, לכל מטרה, בין מסחרית ובין שאינה מסחרית, שאיננה לצורך שימוש אישי ופרטי, למעט בכפוף לקבלת אישור ו/או הסכמה מפורשת מראש ובכתב.
                </p>
                <p className="paragraph">
                    2.8 .אין להפעיל או לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Robots Crawlers וכדומה, לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מתוך האתר. בכלל זה, אין ליצור ואין להשתמש באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו תכנים מהאתר ו/או מהיישומון.
                </p>
                <p className="paragraph">
                    3.8 .אין להציג תכנים מהאתר ו/או מהיישומון בכל דרך שהיא ובכלל זה באמצעות כל תוכנה, מכשיר, אביזר או פרוטוקול תקשורת המשנים את עיצובם באתר ו/או ביישומון או מחסירים מהם תכנים כלשהם ובפרט פרסומות ותכנים מסחריים.
                </p>
                <p className="paragraph">
                    4.8 .אין לקשר לאתר ו/או ליישומון כל אתר המכיל תכנים פורנוגראפיים, תכנים המעודדים לגזענות או להפליה פסולה, או המנוגדים לחוק, או שפרסומם מנוגד לחוק או המעודדים פעילות המנוגדת לחוק.
                </p>
                <p className="paragraph">
                    5.8 .אין לקשר לתכנים מהאתר, שאינם דף הבית של האתרים )"קישור עמוק"( ואין להציג, או לפרסם תכנים כאמור בכל דרך אחרת, אלא אם הקישור העמוק יהיה לדף אינטרנט באתר במלואו וכפי שהוא )"IS AS )"כך שניתן יהיה לצפות ולהשתמש בו באופן הזהה לחלוטין לשימוש ולצפייה בו באתר. במסגרת זו, חל איסור לקשר לתכנים מהאתר, במנותק מדפי האינטרנט שבהם הם מופיעים באתרים )לדוגמה: אסור לקשר במישרין לתמונה או לקובץ גרפי באתר, אלא לעמוד המלא שבו הם מופיעים(. כמו כן על כתובתו המדויקת של דף האינטרנט באתר להופיע במקום הרגיל המיועד לכך בממשק המשתמש, לדוגמה: בשורת הכתובת )Bar Status)""
                    בדפדפן של המשתמש. אין לשנות, לסלף או להסתיר כתובת זו ואין להחליפה בכל כתובת אחרת;
                </p>
                <p className="paragraph">
                    6.8 .Bondi  רשאית לדרוש ביטול כל קישור עמוק כאמור לפי שיקול דעתה הבלעדי ובמקרה זה לא תעמוד כל טענה, דרישה או תביעה כלפיה בעניין זה.
                </p>
                <p className="paragraph">
                    7.8 .Bondi  לא תישא בכל אחריות לכל נזק שייגרם כתוצאה מכל קישור לתכנים מהאתר ו/או מהיישומון ומכל הצגה או פרסום של תכנים כאמור בכל דרך אחרת. האחריות המלאה והבלעדית לכל קישור, הצגה או פרסום של התכנים, היא על מבצע הקישור בלבד.
                </p>
                <p className="paragraph">
                    8.8 .על הגולש לשפות את Bondi ,עובדיה, מנהליה, שותפיה העסקיים או מי מטעמה בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם - ובכלל זה שכ"ט עו"ד והוצאות משפט עקב הפרת תקנון זה. בנוסף, ישפה המשתמש את Bondi ,עובדיה, מנהליה או מי מטעמה בגין כל טענה, תביעה ו/או דרישה שתועלה נגדם על ידי צד שלישי כלשהו כתוצאה מתכנים שנמסרו על ידו לפרסום באתר ו/או ביישומון ו/או באמצעות שירותי האתר ו/או היישומון וכן כתוצאה מקישורים שביצע לאתר ו/או ליישומון.
                </p>
            </div>
            <div className="section">
                <h2 className="title">
                    שינויים באתר ו/או ביישומון, תקלות והפסקות שירות:
                </h2>
                <p className="paragraph">
                    1.9 .מבלי לגרוע מהאמור לעיל, Bondi יוכלה לשנות מעת לעת את מבנה האתר
                    ו/או היישומון, ו/או המראה ו/או העיצוב של האתר ו/או היישומון, את היקפם
                    וזמינותם של השירותים באתר ו/או ביישומון, תהיה רשאית לגבות תשלום בעד
                    תכנים ושירותים כאלה או אחרים על פי החלטתה. כמו כן, Bondi תהיה
                    רשאית לשנות כל היבט אחר הכרוך באתר ו/או ביישומון והכל, בלא צורך להודיע על
                    כך מראש.
                </p>

                <p className="paragraph">
                    2.9 .שינויים כאמור יבוצעו, בין השאר, בהתחשב באופי הדינאמי של רשת האינטרנט
                    ובשינויים הטכנולוגיים והאחרים המתרחשים בה. מטבעם, שינויים מסוג זה עלולים
                    להיות כרוכים בתקלות ו/או לעורר בתחילה אי נוחות וכיו"ב. לא תהיה לגולשים
                    באתר ו/או ביישומון כל טענה, תביעה ו/או דרישה כלפי WAPILAND בגין ביצוע
                    שינויים כאמור ו/או תקלות שיתרחשו אגב ביצועם.
                </p>

                <p className="paragraph">
                    3.9 .Bondi אינה מתחייבת ששירותי האתר ו/או היישומון לא יופרעו, יינתנו
                    כסדרם או בלא הפסקות, יתקיימו בבטחה וללא טעויות ויהיו חסינים מפני גישה
                    בלתי מורשית למחשביה או מפני נזקים, קלקולים, תקלות או כשלים - והכל,
                    בחומרה, בתוכנה, בקווי ובמערכות תקשורת אצל WAPILAND או אצל מי
                    מספקיה.
                </p>
            </div>
            <div className="section">
                <h2 className="title">
                    הרשאה לדיוור, פרסומים ופרסומת:
                </h2>
                <p className="paragraph">
                    1.10. גולש שהשאיר פרטים באתר ו/או ביישומון ומצורף לרשימת הדיוור של
                    Bondi , מאשר שימוש בפרטיו לצורך קבלת מידע שיווקי, עדכונים ופרסומות
                    שתבצע Bondi מעת לעת.
                </p>

                <p className="paragraph">
                    2.10 .על גולש שהשאיר פרטים כאמור תחולנה הוראות הדיוור המפורטות בתקנון להלן.
                </p>

                <p className="paragraph">
                    3.10 .אין להשאיר פרטים של אדם אחר באתר ו/או ביישומון שלא בהסכמתו ו/או ללא
                    נוכחותו מול המסך בשעת השארת הפרטים ולאחר שהוסברו לו כל תנאי התקנון.
                </p>

                <p className="paragraph">
                    4.10 .בעת השארת הפרטים יתבקש הגולש למסור פרטים אישיים כגון: שם פרטי, שם
                    משפחה, וכן כתובת דואר אלקטרוני פעילה. מסירת פרטים חלקיים או שגויים
                    עלולים למנוע את האפשרות להשתמש בשירות ולסכל יצירת קשר במקרה הצורך.
                    במקרה של שינוי פרטים יש לעדכנם באתר ו/או ביישומון.
                </p>

                <p className="paragraph">
                    5.10 .מובהר כי אין חובה על-פי חוק למסור פרטים באתר ו/או ביישומון, אולם בלא
                    למוסרם לא ניתן יהיה לקבל תוכן שיווקי ועדכונים מ- Bondi .
                </p>

                <p className="paragraph">
                    6.10 .Bondi  לא תעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות הפרטיות
                    שלה המהווה חלק בלתי נפרד מהתקנון.
                </p>

                <p className="paragraph">
                    7.10 .השארת פרטים באתר ו/או ביישומון ואישור לקבלת תוכן שיווקי כוללת, בין היתר,
                    קבלת תוכן שיווקי, מידע ביחס למבצעים, עדכונים והנחות המוצעים למשתמשים
                    רשומים.
                </p>

                <p className="paragraph">
                    8.10 .אישור דיוור )קבלת תוכן שיווקי( כאמור, מהווה את הסכמתו של הגולש למשלוח
                    דברי פרסומת על-פי חוק התקשורת.
                </p>

                <p className="paragraph">
                    9.10 .מובהר כי באפשרות משאיר הפרטים להסיר עצמו בכל עת מהדיוור באמצעות
                    לחיצה על "להסרה מרשימת התפוצה" שמופיע בתחתית כל דיוור שנשלח או
                    באמצעות פנייה בדוא"ל ל- Bondi .כל עוד לא הסיר עצמו הנרשם מרשימת
                    הדיוור כאמור, רשאית Bondi ,בכפוף לחוק התקשורת, להעביר לנרשם דיוור
                    ישיר כאמור.
                </p>

                <p className="paragraph">
                    10.10 .אין לראות במידע בדיוור משום הבטחה לתוצאה כלשהי ו/או אחריות לשירות
                    המוצע בו.
                </p>

                <p className="paragraph">
                    11.10 .הדיוור בכללותו, לרבות כל המידע המופיע בו, מוצע כמות שהוא, ויהיה מדויק ונכון
                    ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או
                    אחרות במידע.
                </p>

                <p className="paragraph">
                    12.10 .הגולש מאשר כי לא תהיה לו כל טענה בקשר למודעות פרסום ו/או פרסומות
                    המוצגות באתר ו/או ביישומון. מובהר כי
                    בכל הנוגע למודעות פרסום המוצגות בחסות צד שלישי, ל- Bondi אין כל
                    התערבות בבחירת הפרסומות המוצגות, אמיתות תוכנן וסדר הופעתן.
                </p>
            </div>
            <div className="section">
                <h2 className="title">
                    קניין רוחני:
                </h2>
                <p className="paragraph">
                    1.11. כל זכויות היוצרים והקניין הרוחני הם בבעלות Bondi בלבד, או בבעלות צד
                    שלישי, שהרשה לה לעשות שימוש על פי דין בתכנים אלו ובכלל זה שותפיה
                    העסקיים של Bondi .
                </p>

                <p className="paragraph">
                    2.11. אין להעתיק, להפיץ, להציג בפומבי, לבצע בפומבי, להעביר לציבור, לשנות, לעבד,
                    ליצור יצירות נגזרות, למכור או להשכיר כל חלק מן הנ"ל, בין ישירות ובין באמצעות
                    או בשיתוף צד שלישי, בכל דרך או אמצעי בין אם אלקטרוניים, מכאניים, אופטיים,
                    אמצעי צילום או הקלטה, או בכל אמצעי ודרך אחרת, בלא קבלת הסכמה בכתב
                    ומראש מ- Bondi או מבעלי הזכויות האחרים, לפי העניין, ובכפוף לתנאי
                    ההסכמה )ככל שתינתן(. הוראה זו תקפה גם ביחס לכל עיבוד, עריכה או תרגום
                    שנעשו על ידי Bondi לתכנים שהוזנו או נמסרו על ידי הגולשים לאתר ו/או
                    ליישומון.
                </p>

                <p className="paragraph">
                    3.11. אם וככל שניתנה הסכמה כאמור, יש להימנע מלהסיר, למחוק או לשבש כל הודעה
                    או סימן בעניין זכויות קניין רוחני, לדוגמה: סימון זכויות היוצרים ,© או סימן מסחר
                    R, הנלווים לתכנים שיעשה בהם שימוש.
                </p>

                <p className="paragraph">
                    4.11. סימני המסחר, צילומים, תמונות תכנים ומודעות הפרסומת של שותפיה העסקיים
                    של Bondi הינם קניינם של מפרסמים אלה בלבד. גם בהם אין לעשות בהם
                    שימוש בלא הסכמת המפרסם מראש ובכתב.
                </p>
            </div>
            <div className="section">
                <h2 className="title">
                    סמכות שיפוט:
                </h2>
                <p className="paragraph">
                    1.12. על תקנון זה יחולו אך ורק דיני מדינת ישראל, אולם לא תהיה תחולה לכללי ברירת
                    הדין הבינלאומי הקבועים בהם.
                </p>

                <p className="paragraph">
                    2.12. לבתי המשפט במחוז תל אביב-יפו תהיה סמכות שיפוט ייחודית בכל עניין הנובע
                    ו/או הקשור לתקנון זה.
                </p>
            </div>

            <div className="section">
                <h2 className="title">
                    מדיניות פרטיות:
                </h2>
                <p className="paragraph">
                    1.13. Bondi מכבדת את פרטיות הלקוחות.
                </p>

                <p className="paragraph">
                    2.13. על מנת לספק שירות איכותי, אנו עשויים להשתמש בנתונים האישיים שלך, ובין
                    היתר, מידע על השימוש שלך באתר ו/או ביישומון ומידע על המכשיר הנייד שלך או
                    המחשב )"המידע הנאסף באתר"(. המידע הנאסף באתר עשוי לשמש את
                    Bondi לצרכים הבאים:
                </p>

                <ul>
                    <li className="paragraph">
                        לספק לך שירותים ולשפר את האתר ו/או את היישומון ו/או את
                        השירותים;
                    </li>
                    <li className="paragraph">
                        תפעולו התקין של האתר ו/או היישומון;
                    </li>
                    <li className="paragraph">
                        לנתח את ולנהל את האתר ו/או היישומון באופן תקין;
                    </li>
                    <li className="paragraph">
                        שיפור שירות הלקוחות של האתר ו/או היישומון;
                    </li>
                    <li className="paragraph">
                        ליצירת קשר או לספק לך נתונים בקשר לאתר או ליישומון או לשירות.
                    </li>
                </ul>

                <p className="paragraph">
                    3.13. בעת השימוש באתר ו/או ביישומון, האתר ו/או היישומון עשוי לאסוף מידע מסוים
                    באופן אוטומטי. לדוגמה:
                </p>

                <ul>
                    <li className="paragraph">
                        כתובת ה- IP שלך, פרוטוקול האינטרנט, ספק האינטרנט או הדפדפן
                        וסוג המכשיר ממנו אתה גולש;
                    </li>
                    <li className="paragraph">
                        הקלטת הפעילות שלך באתר ו/או ביישומון או תרשים העמודים בהם
                        ביקרת;
                    </li>
                    <li className="paragraph">
                        שימוש בעוגיות על מנת לזהות את המכשיר ממנו אתה גולש. קובץ
                        עוגיות הם קובץ טקסט קטן שאתר, יישום מקוון או דואר אלקטרוני עשויים
                        לשמור בדפדפן האינטרנט ו/או בכונן הקשיח של המחשב לשימוש בביקורים
                        הבאים באתר;
                    </li>
                    <li className="paragraph">
                        מידע שתזין, תשתף או שניתן להשיג מהשימוש שלך באתר ו/או
                        ביישומון.
                    </li>
                </ul>

                <p className="paragraph">
                    4.13. במידה והאתר ו/או היישומון ימוזג לתוך פעילות גוף אחר או אם האתר ו/או היישומון
                    יעבור לבעלות תאגיד אחר ניתן יהיה להעביר לתאגיד החדש את המידע הקיים
                    באתר ו/או ביישומון, אבל רק במקרה שהתאגיד יתחייב לשמור על תנאי תקנון זה.
                </p>

                <p className="paragraph">
                    5.13. לנותני שירותים ושותפים עסקיים כאמור ניתנת גישה לכל או לחלק מהמידע שלך,
                    והם עשויים להשתמש בעוגיות (כהגדרתן לעיל) או בטכנולוגיית איסוף אחרות.
                </p>

                <p className="paragraph">
                    6.13. חשוב לזכור שלא ניתן לערוב במאת האחוזים מפני פעילות עוינת ונחושה מצד
                    גורמים זרים ולכן אין בפעולות אלה בטחון מוחלט ו- Bondi לא מתחייבת
                    שהשירותים באתר ו/או ביישומון יהיו חסינים באופן מוחלט מפני גישה בלתי
                    מורשת למידע שנאסף בו.
                </p>
            </div>
            <div className="section">
                <h2 className="title">
                    הרשאה לדיוור, פרסומים ופרסומת:
                </h2>
                <p className="paragraph">
                    1.14 רוכש שהשאיר פרטים אצל הספק ומצורף לרשימת הדיוור של הספק, מאשר שימוש
                    בפרטיו לצורך קבלת מידע שיווקי, עדכונים ופרסומות שיבצע הספק מעת לעת.
                </p>

                <p className="paragraph">
                    2.14 על גולש שהשאיר פרטים כאמור תחולנה הוראות הדיוור המפורטות בתקנון להלן.
                </p>

                <p className="paragraph">
                    3.14 אין להשאיר פרטים של אדם אחר שלא בהסכמתו ו/או ללא נוכחותו מול המסך
                    בשעת השארת הפרטים ולאחר שהוסברו לו כל תנאי התקנון.
                </p>

                <p className="paragraph">
                    4.14 בעת השארת הפרטים יתבקש הגולש למסור פרטים אישיים כגון: שם פרטי, שם
                    משפחה, וכן כתובת דואר אלקטרוני פעילה. מסירת פרטים חלקיים או שגויים
                    עלולים למנוע את האפשרות להשתמש בשירות ולסכל יצירת קשר במקרה הצורך.
                    במקרה של שינוי פרטים יש לעדכנם אצל הספק.
                </p>

                <p className="paragraph">
                    5.14 מובהר כי אין חובה על-פי חוק למסור פרטים לספק, אולם בלא למוסרם לא ניתן
                    יהיה לקבל תוכן שיווקי ועדכונים מהספק.
                </p>

                <p className="paragraph">
                    6.14 הספק לא יעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות הפרטיות של
                    הספק המהווה חלק בלתי נפרד מהתקנון.
                </p>

                <p className="paragraph">
                    7.14 השארת פרטים לספק ואישור לקבלת תוכן שיווקי כוללת, בין היתר, קבלת תוכן
                    שיווקי, מידע ביחס למבצעים, עדכונים והנחות המוצעים למשתמשים רשומים.
                </p>

                <p className="paragraph">
                    8.14 אישור דיוור (קבלת תוכן שיווקי) כאמור, מהווה את הסכמתו של הלקוח למשלוח
                    דברי פרסומת על-פי חוק התקשורת (בזק ושידורים) (תיקון מס' 40) התשס"ח –
                    2008 ("חוק התקשורת").
                </p>

                <p className="paragraph">
                    9.14 מובהר כי באפשרות משאיר הפרטים להסיר עצמו בכל עת מהדיוור באמצעות
                    לחיצה על "להסרה מרשימת התפוצה" שמופיע בתחתית כל דיוור שנשלח או
                    באמצעות פנייה בדוא"ל לספק. כל עוד לא הסירו עצמו הנרשם מרשימת הדיוור
                    כאמור, רשאי הספק, בכפוף לחוק התקשורת, להעביר לנרשם דיוור ישיר כאמור.
                </p>

                <p className="paragraph">
                    10.14 אין לראות במידע בדיוור משום הבטחה לתוצאה כלשהי ו/או אחריות לשירות המוצע
                    בו.
                </p>

                <p className="paragraph">
                    11.14 הדיוור בכללותו, לרבות כל המידע המופיע בו, מוצע כמות שהוא, ויהיה מדויק ונכון
                    ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או
                    אחרות במידע.
                </p>
            </div>
            <div className="section">
                <h2 className="title">
                    השימוש במוצרים ובתוכנות:
                </h2>
                <p className="paragraph">
                    1.15 השימוש בפלטפורמה ובמוצרים מותר למטרות פרטיות ואישיות בלבד. אין להעתיק
                    ולהשתמש או לאפשר לאחרים להשתמש, בכל דרך אחרת בתכנים ו/או במוצרים
                    של הספק, לרבות באתרי אינטרנט אחרים, בפרסומים אלקטרוניים, בפרסומי דפוס
                    וכיו"ב, לכל מטרה, בין מסחרית ובין שאינה מסחרית, שאיננה לצורך שימוש אישי
                    ופרטי. הפרת תנאי זה תוביל לביטול העסקה ולהפסקת השימוש במוצר לאלתר
                    וללא החזר כספי.
                </p>

                <p className="paragraph">
                    2.15 אין להפעיל או לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות
                    מסוג bondi וכדומה, לשם חיפוש, סריקה, העתקה או אחזור
                    אוטומטי של תכנים מתוך הפלטפורמה. בכלל זה, אין ליצור ואין להשתמש
                    באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו תכנים מהפלטפורמה.
                </p>

                <p className="paragraph">
                    3.15 אין להציג תכנים מהפלטפורמה בכל דרך שהיא ובכלל זה באמצעות כל פלטפורמה,
                    מכשיר, אביזר או פרוטוקול תקשורת המשנים את עיצובם בפלטפורמה או מחסירים
                    מהם תכנים כלשהם ובפרט פרסומות ותכנים מסחריים.
                </p>

                <p className="paragraph">
                    4.15 אין לקשר לספק תכנים פורנוגראפיים, תכנים המעודדים לגזענות או להפליה
                    פסולה, או המנוגדים לחוק, או שפרסומם מנוגד לחוק או המעודדים פעילות
                    המנוגדת לחוק.
                </p>

                <p className="paragraph">
                    5.15 על הגולש לשפות את הספק, עובדיו, מנהליו, שותפיו העסקיים או מי מטעמו בגין
                    כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם - ובכלל זה שכ"ט עו"ד
                    והוצאות משפט עקב הפרת תקנון זה. בנוסף, ישפה המשתמש את הספק, עובדיו,
                    מנהליו או מי מטעמו בגין כל טענה, תביעה ו/או דרישה שתועלה נגדם על ידי צד
                    שלישי כלשהו כתוצאה מתכנים שנמסרו על ידו לפרסום בתוכנות ובאמצעות
                    המוצרים .
                </p>
            </div>
            <div className="section">
                <h2 className="title">
                    מדיניות פרטיות:
                </h2>
                <p className="paragraph">
                    1.16 הספק מכבד את פרטיות הלקוחות.
                </p>
                <p className="paragraph">
                    1.1.16 לספק לך שירותים ולשפר את השירותים;
                </p>
                <p className="paragraph">
                    2.1.16 תפעולו התקין של הפלטפורמה ומוצרים נוספים;
                </p>
                <p className="paragraph">
                    3.1.16 לנתח את ולנהל את הפלטפורמה באופן תקין;
                </p>
                <p className="paragraph">
                    4.1.16 שיפור שירות הלקוחות של הספק;
                </p>
                <p className="paragraph">
                    5.1.16 ליצירת קשר או לספק לך נתונים בקשר למוצרים או לתוכנות או
                    לשירות.
                </p>

                <p className="paragraph">
                    2.16 אנו עשויים לשתף את המידע האישי שלך (למעט מידע רגיש כגון פרטי אשראי
                    מספרי תעודות זהות) עם צדדים שלישיים, כולל עם שותפינו העסקיים ונותני
                    שירותים.
                </p>

                <p className="paragraph">
                    3.16 דוגמאות לפעולות נותני שירותים עשויים לבצע הכרוכים במידע שלך:
                </p>

                <p className="paragraph">
                    4.16 במידה והספק ימוזג לתוך פעילות גוף אחר או אם הספק יעבור לבעלות תאגיד אחר
                    ניתן יהיה להעביר לתאגיד החדש את המידע הקיים אצל הספק, אבל רק במקרה
                    שהתאגיד מחויב לשמור על תנאי תקנון זה.
                </p>

                <p className="paragraph">
                    5.16 לנותני שירותים ושותפים עסקיים כאמור ניתנת גישה לכל או לחלק מהמידע שלך,
                    והם עשויים בהן.
                </p>

                <p className="paragraph">
                    6.16 חשוב לזכור שלא ניתן לערוב במאת האחוזים מפני פעילות עוינת ונחושה מצד
                    גורמים זרים ולכן אין בפעולות אלה בטחון מוחלט והספק לא מתחייב שהמוצרים
                    לרבות תוכנות ו/או השירותים יהיו חסינים באופן מוחלט מפני גישה בלתי מורשית
                    למידע הנאסף בו.
                </p>

                <p className="paragraph">
                    מדיניות ביטולים: על פי חוק הגנת צרכן עסקאות מכר רחוקות.
                </p>
            </div>

            <div className="section">
                <h2 className="title">
                    צרו קשר:
                </h2>
                <p className="paragraph">
                    1.17 .Bondi מקפידה על קיום הוראות החוק ומכבדת את זכותם של משתמשי
                    האתר ו/או היישומון ואחרים לפרטיות ולשם טוב. אם אתה סבור כי פורסם באתר
                    ו/או ביישומון תוכן הפוגע בך מטעם כלשהו, נא פנה אלינו לפי הפרטים שלהלן ואנו
                    נשתדל לטפל בפנייתך בכל ההקדם. פניות כאמור ניתן להעביר באמצעים הבאים:
                </p>
                <p className="paragraph">
                    טלפון: 0502886610
                </p>
                <p className="paragraph">
                    דוא"ל:
                </p>
                <p className="paragraph">
                    מדיניות ביטולים:
                </p>
            </div>
        </div>
    )
}