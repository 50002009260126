import React from "react";
import "./dropdown.model.css"

interface Props {
    setStatus: (status: boolean) => void
    status: boolean
}

export const DropdownModel: React.FC<React.PropsWithChildren & Props> = ({ children, setStatus, status }) => {
    return (
        <>
            <div className="dropdown_model_out" onClick={() => setStatus(false)} style={{ display: status === false ? 'none' : "inherit" }}></div>
            <div className="dropdown_model" style={{ display: status === false ? 'none' : "" }} >
                {children}
            </div>
        </>
    )
}