import './recommendation-card.css'

export const RecommendationCard: React.FC<{ name: string, subject: string, description: string, date: string, animate?: boolean }> = ({ name, subject, description, date, animate }) => {

    return (
        <div className={`recommendation_card ${animate ? 'animate' : ''}`}>
            <div className="recommendation_card__wrapper">
                <h3><strong>{name}</strong> - {subject}</h3>
                <p>{description}</p>
            </div>
            <div className="recommendation_card__footer_wrapper">
                {/* <span>{date}</span> */}
            </div>
        </div>
    )
}