import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

const resources = {
    en: {
        translation: {
            navbar_translation: {
                link_text: {
                    contactUs: 'Contact Us',
                    features: 'Features',
                    tutorials: 'Tutorials',
                },

                button_text: {
                    bookDemo: 'book demo',
                    signIn: 'Sign In',
                },
            },


            welcome_section_translation: {
                headlines_text: {
                    title: "Flat 25% discount",
                    subTitle: "on Bondi’s ultimate marketing tool",
                    description: "Introducing Bondi's marketing revolution - All the tools you need to grow your business, now amplified with WhatsApp. Reach more, engage more, grow more.",
                },

                minimized_lead_form_text: {
                    field_text: {
                        fullNameLabel: "What is your name?",
                        fullNameInputPlaceholder: "Full Name",
                        phoneNumberLabel: "What is your phone number?",
                        phoneNumberInputPlaceholder: "Phone Number",
                    },
                    button_text: {
                        requestCall: 'Request a Call'
                    }
                },


            },

            discount_post_translation: {
                headlines_text: {
                    title: 'Claim your 25% OFF',
                    subTitle: "Join the Revolution with Bondi! Get on our waiting list and be among the first to leverage the next evolution in marketing. It's smarter, it's better, it's Bondi.",
                    description: "Join the Revolution with Bondi! Get on our waiting list and be among the first to leverage the next evolution in marketing. It's smarter, it's better, it's Bondi.",
                },

                button_text: {
                    joinUs: "Join us - Get 25% OFF"
                },
            },

            bondi_feature_presentation_translation: {
                headlines_text: {
                    title: 'Bondi <span id="bondi_feature_presentation__title_colorful_span">Features</span>',
                    subTitle: {
                        mobile: "Discover the unique features of Bondi: take your WhatsApp campaigns to new heights",
                        default: "Discover the unique features of Bondi: take your WhatsApp campaigns to new heights",
                    },
                },

                features_list_right_side: [
                    {
                        featureName: "Black List + Sending rate",
                        featureDescription: "Set up a blacklist that any phone number on the list will never send messages to. Control your sending rate to optimize your campaigns"
                    },
                    {
                        featureName: "History",
                        featureDescription: "Easily track your latest campaigns"
                    },
                    {
                        featureName: "Schedule Campaign",
                        featureDescription: "Instead of stressing about remembering to send messages with us you can schedule it."
                    },
                    {
                        featureName: "Manage Campaigns",
                        featureDescription: `Manage your campaign and edit campaign contacts with these features:
                        Add more
                        Delete duplicates`
                    },
                ],

                features_list_left_side: [
                    {
                        featureName: "Send Messages",
                        featureDescription: "Start a campaign with a click of a button and set final settings before starting"
                    },
                    {
                        featureName: "Create Campaign",
                        featureDescription: "Create a campaign quickly and easily using Excel files, WhatsApp contacts or WhatsApp groups"

                    },
                    {
                        featureName: "Whatsapp Bot",
                        featureDescription: "Easily and quickly set up a bot to automatically chat with your customers"

                    },
                    {
                        featureName: "Calendar",
                        featureDescription: `Organized and efficient calendar management
                        There you can see your campaigns and meetings`

                    }
                ]

            },

            join_bondi_form: {
                headlines_text: {
                    title: '<span id="join_bondi_form__title_colorful_span">Join</span> Bondi',
                }
            },

            recommendations: [
                {
                    name: 'Eylon',
                    subject: 'Small business owner',
                    description: 'Bondi has made it so much easier for me to stay in touch with my customers and provide them with the best possible service.',
                },
                {
                    name: 'Ariel',
                    subject: 'Marketing manager',
                    description: 'Bondi is a great way to send personalized messages to my customers and get them to come back for more.',
                },
                {
                    name: 'Joni',
                    subject: 'Customer support manager',
                    description: 'Bondi is a lifesaver for our team. It helps us to manage our customer relationships more efficiently and effectively.',
                },
                {
                    name: 'Gil',
                    subject: 'Sales representative',
                    description: `Bondi is the best platform I've ever used. It's easy to use and it helps me to close more deals.`,
                },
            ],

            questions: {
                title: "Got Questions ?",
                description: "Chat with our team directly on WhatsApp. We're here to help you understand our unique features, guide you through the process, and answer any doubts you may have."
            }
        },
    },

    he: {
        translation: {
            navbar_translation: {
                link_text: {
                    contactUs: 'צור קשר',
                    features: 'מאפיינים',
                    tutorials: 'מדריכים',
                },

                button_text: {
                    bookDemo: 'הזמן הדגמה',
                    signIn: 'התחבר',
                },
            },


            welcome_section_translation: {
                headlines_text: {
                    title: "הנחה של 25%",
                    subTitle: "על כלי השיווק המוביל של בונדי",
                    description: "מביאים את מהפכת השיווק של בונדי - כל הכלים שאתם צריך כדי לגדול את העסק שלכם, כעת מוגברים בוואטסאפ. הגיעו ליותר אנשים, שוחחו יותר, גדלו יותר.",
                },

                minimized_lead_form_text: {
                    field_text: {
                        fullNameLabel: "מה שמך?",
                        fullNameInputPlaceholder: "שם מלא",
                        phoneNumberLabel: "מה מספר הטלפון שלכם?",
                        phoneNumberInputPlaceholder: "מספר טלפון",
                    },
                    button_text: {
                        requestCall: 'בקשת שיחה',
                    }
                },


            },

            discount_post_translation: {
                headlines_text: {
                    title: 'קבל הנחה של 25%',
                    subTitle: "הצטרפו למהפכה עם בונדי! הירשמו לרשימת ההמתנה שלנו והיו בין הראשונים שינצלו את הדור הבא של שיווק. זה חכם יותר, זה טוב יותר, זה בונדי.",
                    description: "הצטרפו למהפכה עם בונדי! הירשמו לרשימת ההמתנה שלנו והיו בין הראשונים שינצלו את הדור הבא של שיווק. זה חכם יותר, זה טוב יותר, זה בונדי.",
                },

                button_text: {
                    joinUs: "הצטרפו אלינו - קבל 25% הנחה",
                },
            },

            bondi_feature_presentation_translation: {
                headlines_text: {
                    title: 'הכלים של <span id="bondi_feature_presentation__title_colorful_span">בונדי</span>',
                    subTitle: {
                        mobile: "גלו את המאפיינים הייחודיים של בונדי: מגביה את הקמפיינים שלכם בוואטסאפ לגבהים חדשים",
                        default: "גלו את המאפיינים הייחודיים של בונדי: מגביה את הקמפיינים שלכם בוואטסאפ לגבהים חדשים",
                    },
                },

                features_list_right_side: [
                    {
                        featureName: "רשימה שחורה + שיעור שליחה",
                        featureDescription: "הגדרת רשימה שחורה שכל מספר טלפון שנמצא ברשימה לעולם לא ישלח אליו הודעות. שליטה בשיעור השליחה כדי למטב את הקמפיינים שלכם",
                    },
                    {
                        featureName: "היסטוריה",
                        featureDescription: "מעקב קל אחר הקמפיינים האחרונים שלכם",
                    },
                    {
                        featureName: "תזמון קמפיינים",
                        featureDescription: "במקום להתמקד בזכירת שליחת הודעות איתנו אתם יכולים לתזמן אותן.",
                    },
                    {
                        featureName: "ניהול קמפיינים",
                        featureDescription: "נהלו את הקמפיינים שלכם וערוכו את אנשי הקשר בקמפיין עם התכונות האלו: הוסף עוד, מחק כפילויות",
                    },
                ],

                features_list_left_side: [
                    {
                        featureName: "שלח הודעות",
                        featureDescription: "התחל קמפיין בלחיצת כפתור וקבעו הגדרות סופיות לפני ההתחלה",
                    },
                    {
                        featureName: "צור קמפיין",
                        featureDescription: "צור קמפיין במהירות ובקלות באמצעות קבצי אקסל, אנשי קשר מהוואטסאפ או קבוצות בוואטסאפ",
                    },
                    {
                        featureName: "בוט לוואטסאפ",
                        featureDescription: "הגדרת בוט בקלות ובמהירות לשיחה אוטומטית עם הלקוחות שלכם",
                    },
                    {
                        featureName: "לוח שנה",
                        featureDescription: "ניהול לוח שנה מאורגן ויעיל שם אתם יכולים לראות את הקמפיינים והפגישות שלכם",
                    }
                ]

            },

            join_bondi_form: {
                headlines_text: {
                    title: '<span id="join_bondi_form__title_colorful_span">הצטרפו</span> לבונדי',
                }
            },

            recommendations: [
                {
                    name: 'איילון',
                    subject: ' בעל עסק קטן',
                    description: 'בונדי הקל עליי לשמור על קשר עם הלקוחות שלי ולספק להם את השירות הטוב ביותר.'
                },
                {
                    name: 'אריאל',
                    subject: 'מנהל שיווק',
                    description: 'בונדי היא דרך מצוינת לשלוח הודעות מותאמות אישית ללקוחות שלי ולגרום להם לחזור לעוד.'
                },
                {
                    name: `ג'וני`,
                    subject: 'מנהל תמיכת לקוחות',
                    description: 'בונדי הוא מציל חיים עבור הצוות שלנו. זה עוזר לנו לנהל את קשרי הלקוחות שלנו בצורה יעילה ואפקטיבית יותר.'
                },
                {
                    name: 'גיל',
                    subject: 'נציג מכירות',
                    description: 'בונדי היא הפלטפורמה הטובה ביותר שאי פעם השתמשתי בה. זה קל לשימוש וזה עוזר לי לסגור עוד עסקאות.'
                },
            ],

            questions: {
                title: "? יש לך שאלות",
                description: "שוחח עם צוותנו ישירות בוואטסאפ. אנחנו כאן כדי לעזור לך להבין את התכונות הייחודיות שלנו, להדריך אותך בתהליך, ולענות על כל ספק שיש לך."
            }
        },
    },
}


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'he',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n