import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Page } from './app/views/page';

function App() {

  const [isUnderNavbar, setIsUnderNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const navbarHeight = (document.getElementById('navbar_wrapper_fixed') as HTMLElement).offsetHeight;
      const excludedList = ["html", "body", "#root", ".App", "#navbar", "#navbar_wrapper_fixed", "#navbar_wrapper_fixed *"]
      const contentElements = document.querySelectorAll(`* > :not${excludedList.map(tag => `(${tag})`).join(":not")}`);
      contentElements.forEach(element => {
        const { top } = element.getBoundingClientRect();
        const isUnderNavbar = top - navbarHeight + element.clientHeight - 70 <= -10;
        if (isUnderNavbar) {
          (element as HTMLElement).style.opacity = '0.2'; 
          (element as HTMLElement).style.transition = '1s';
        } else {
          (element as HTMLElement).style.opacity = '1';
          (element as HTMLElement).style.transition = '.5s'
        }
      });
      
      setIsUnderNavbar(isUnderNavbar);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <Page />
    </div>
  );
}

export default App;
