import { IApi } from './api.interface'
import { db } from "../../config/firebase";
import { addDoc, collection, CollectionReference, DocumentData, getDocs, QueryDocumentSnapshot, QuerySnapshot, Timestamp } from "firebase/firestore";
import { Utils } from '../utils';


export class API implements IApi.Class {


    private async addLeads(payload: IApi.ILead.Payload) {
        const ref = collection(db, 'leads');
        const response = await addDoc(ref, payload);
        return response;
    }

    private async getLeads(): Promise<(IApi.ILead.Payload & { id: string })[]> {
        const ref = collection(db, 'leads');
        const response = await getDocs(ref) as QuerySnapshot<IApi.ILead.Payload>;
        const leads = response.docs.map((doc: QueryDocumentSnapshot<IApi.ILead.Payload>): IApi.ILead.Payload & { id: string; } => {
            return {
                ...doc.data(),
                id: doc.id,
            }
        });

        return leads;
    }

    private async getDiscountGap(): Promise<{ startDate: number, endDate: number }[]> {
        const ref = collection(db, 'discount-gap');
        const response = await getDocs(ref) as QuerySnapshot<{ startDate: Timestamp, endDate: Timestamp }>;
        const dates = response.docs.map((doc: QueryDocumentSnapshot<{ startDate: Timestamp, endDate: Timestamp }>) => {
            const data = doc.data();
            return {
                startDate: data.startDate.seconds,
                endDate: data.endDate.seconds
            }
        });
        return dates;
    }

    public async fetchLeads() {
        const leads = await this.getLeads();
        return leads;
    }

    public async postNewLead(payload: IApi.ILead.Payload): Promise<void | { status: 200 }> {
        if (!payload.fullName || !payload.phoneNumber) {
            return;
        }
        await this.addLeads(payload);
        return { status: 200 };
    }

    public async fetchDiscountGap(): Promise<{ startDate: number, endDate: number }> {
        const dates = await this.getDiscountGap();
        const $Date = new Utils.Moment();
        const date = dates.find(({ startDate, endDate }) => {
            const isBetween = $Date.isBetweenGap({ startTimestamp: startDate * 1000, endTimestamp: endDate * 1000 });
            if (isBetween) return true;
            else return false;
        })

        if (!date) {
            return {
                startDate: 0,
                endDate: 0,
            }
        }
        else return { startDate: date.startDate * 1000, endDate: date.endDate * 1000 };



    }

}