import { PropsWithChildren } from "react";
import "./phone-view-port.css"
import { useMediaQuery } from "react-responsive";

export const PhoneViewPort: React.FC<PropsWithChildren> = ({ children }) => (
    <div className="phone_view_port__smartphone">
        <div className="phone_view_port__content">
            {children}
        </div>
    </div>
)