import { useState, useEffect, SetStateAction } from 'react';

interface TimeObject {
    y: string;
    m: string;
    d: string;
    h: string;
    min: string;
    sec: string;
}

export const useDecreaseTime = (initialTime: TimeObject): [TimeObject, SetStateAction<any>] => {
    const [time, setTime] = useState<TimeObject>(initialTime);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => {
                const { y, m, d, h, min, sec } = prevTime;

                // Convert time values to numbers and decrement seconds
                let seconds = parseInt(sec, 10);
                let minutes = parseInt(min, 10);
                let hours = parseInt(h, 10);
                let days = parseInt(d, 10);
                let months = parseInt(m, 10);
                let years = parseInt(y, 10);

                if ([seconds, minutes, hours, days, months, years,].every(item => item === 0)) return {
                    y: '0',
                    m: '0',
                    d: '0',
                    h: '0',
                    min: '0',
                    sec: '0',
                };

                seconds -= 1;



                // Handle negative values and adjust other time units accordingly
                if (seconds < 0) {
                    seconds = 59;
                    minutes -= 1;
                }
                if (minutes < 0) {
                    minutes = 59;
                    hours -= 1;
                }
                if (hours < 0) {
                    hours = 23;
                    days -= 1;
                }
                if (days < 0) {
                    // Assuming 30 days per month for simplicity
                    days = 29;
                    months -= 1;
                }
                if (months < 0) {
                    months = 11;
                    years -= 1;
                }


                // Convert back to strings and return updated time object
                return {
                    y: String(years),
                    m: String(months),
                    d: String(days),
                    h: String(hours),
                    min: String(minutes),
                    sec: String(seconds),
                };
            });
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, [initialTime]);

    return [time, setTime];
};