import { CSSProperties } from "react";
import { icons } from "../../common/constants/icons.constants";
import "./feature-card.css"
import { useMediaQuery } from "react-responsive";

export const FeatureCard: React.FC<{ featureName: string; featureDescription: string; onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void, StyleObject?: CSSProperties, side: 'left' | 'right' }> = ({ featureName, featureDescription, onClick, StyleObject, side }) => {
    const isLapTop = useMediaQuery({ minWidth: 997 })
    return (
        <div data-side={side} style={StyleObject || {}} onClick={onClick} className="feature_card">
            <div className="feature_card__container content_container">
                <div className="feature_card__icon_wrapper">
                    {<icons.CourserPointerIcon size={isLapTop ? 42 : 14} color="#6366F1" />}
                </div>
                <div className="feature_card__content">
                    <h2 style={{ margin: !isLapTop ? 0 : 'none', padding: !isLapTop ? 0 : 'none' }}>{featureName}</h2>
                    <p>{featureDescription}</p>
                </div>
            </div>
        </div>
    )
}