// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCNzDz2r1vbiH8LB18Ne32Dz9wggJ5HUV8",
    authDomain: "bondi-landing-page.firebaseapp.com",
    databaseURL: "https://bondi-landing-page-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "bondi-landing-page",
    storageBucket: "bondi-landing-page.appspot.com",
    messagingSenderId: "257873421932",
    appId: "1:257873421932:web:ef1ec637efbc55d4c3da49",
    measurementId: "G-TG1HJJCDCY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db: Firestore = getFirestore(app);


