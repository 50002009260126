import { assets } from "./assets.constants";

export const presentationImagesData = [
    {
        metadata: {
            name: "Send Messages",
        },
        url: assets.Presentation.SendMessages
    },
    {
        metadata: {
            name: "Create Campaign",
        },
        url: assets.Presentation.CreateCampaign
    },
    {
        metadata: {
            name: "Whatsapp Bot",

        },
        url: assets.Presentation.WhatsappBot
    },
    {
        metadata: {
            name: "Calendar",
        },
        url: assets.Presentation.Calendar
    },
    {
        metadata: {
            name: "Black List + Sending rate",
        },
        url: assets.Presentation.BlackList
    },
    {
        metadata: {
            name: "History",
        },
        url: assets.Presentation.History
    },
    {
        metadata: {
            name: "Schedule Campaign",
        },
        url: assets.Presentation.Schedule,
    },
    {
        metadata: {
            name: "Manage Campaigns",
        },
        url: assets.Presentation.ManageCampaign,
    },

]

export const recommendationCardsData = [
    {
        name: "recommendations.0.name",
        subject: "recommendations.0.subject",
        description: "recommendations.0.description",
        date: "12.18 AM",

    },
    {
        name: "recommendations.1.name",
        subject: "recommendations.1.subject",
        description: "recommendations.1.description",
        date: "12.18 AM",

    },
    {
        name: "recommendations.2.name",
        subject: "recommendations.2.subject",
        description: "recommendations.2.description",
        date: "12.18 AM",

    },
    {
        name: "recommendations.3.name",
        subject: "recommendations.3.subject",
        description: "recommendations.3.description",
        date: "12.18 AM",

    },
]