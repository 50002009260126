import Logo from '../assets/logo.svg'
import LogoNoText from '../assets/logo_no_text.svg'
import DiscountPost from '../assets/discount_post.png'
import Dashboard from "../assets/slider/dashboard.svg"
import Circle from '../assets/circle.svg';
import WhatsappBot from '../assets/presentation/whatsapp-bot.svg';
import History from '../assets/presentation/history.svg';
import ContactPhoneView from "../assets/contact_phone_view.svg"
import Schedule from "../assets/presentation/schedule.svg";
import BlackList from "../assets/presentation/black-list.svg";
import ManageCampaign from "../assets/presentation/manage-campaign.svg";
import SendMessages from "../assets/presentation/Send-messages.svg"
import Calendar from "../assets/presentation/Calendar.svg"
import CreateCampaign from '../assets/presentation/create-campaign.svg'
export const assets = {
    Logo: Logo,
    LogoNoText: LogoNoText,
    Dashboard: Dashboard,
    DiscountPost: DiscountPost,
    Circle: Circle,
    ContactPhoneView: ContactPhoneView,
    ProductsSliders: [
        Dashboard,
        Dashboard,
        Dashboard,
        Dashboard,
        Dashboard,
        Dashboard,
    ],

    Presentation: {
        WhatsappBot: WhatsappBot,
        History: History,
        Schedule: Schedule,
        BlackList: BlackList,
        ManageCampaign: ManageCampaign,
        SendMessages: SendMessages,
        Calendar: Calendar,
        CreateCampaign: CreateCampaign,
        
    }
};


