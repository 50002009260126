import moment from 'moment';

export class MomentUtils {

    getTimestamp(payload: { date: number }): number {
        return payload.date;
    }

    calcDiff(payload: { startDate: number; endDate: number }): {
        y: string;
        m: string;
        d: string;
        h: string;
        min: string;
        sec: string;
    } {
        if (payload.startDate === 0 || payload.endDate === 0) return {
            y: '0',
            m: '0',
            d: '0',
            h: '0',
            min: '0',
            sec: '0',
        };

        const endDate = moment(payload.endDate);
        const now = moment.utc();

        const duration = moment.duration(endDate.diff(now));

        return {
            y: String(duration.years()),
            m: String(duration.months()),
            d: String(duration.days()),
            h: String(duration.hours()),
            min: String(duration.minutes()),
            sec: String(duration.seconds()),
        };
    }

    isBetweenGap(payload: {
        startTimestamp: number;
        endTimestamp: number;
    }): boolean {
        const start = moment(payload.startTimestamp);
        const end = moment(payload.endTimestamp);
        const now = moment();

        return now.isBetween(start, end);
    }

    listDiffObj(payload: {
        y: string;
        m: string;
        d: string;
        h: string;
        min: string;
        sec: string;
    }): string[] {
        return Object.values(payload).map(value => value.padStart(2, '0'));
    }
}