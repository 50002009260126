import React, { useEffect } from "react";
import "./slider.css";

export const Slider: React.FC<{ slides: string[] }> = ({ slides }) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const handleSlider = (action: "+" | "-") => {
        if (action === '+' && slides.length - 2 === currentSlide) {
            setCurrentSlide(0);
        }
        else if (action === "-" && currentSlide === 0) {
            setCurrentSlide(slides.length - 1);
        }
        else if (action === '-') {
            setCurrentSlide((current) => current - 1);
        }
        else {
            setCurrentSlide((current) => current + 1);
        }
    };

    useEffect(() => {
        const handleAnimationIteration = () => {
            handleSlider("+");
        };

        const element = document.querySelector('.slider_img_fade_in');
        if (element) {
            element.addEventListener('animationiteration', handleAnimationIteration);
        }

        return () => {
            if (element) {
                element.removeEventListener('animationiteration', handleAnimationIteration);
            }
        };
    }, [currentSlide, handleSlider]);

    return (
        <>
            <div id="slider__container">
                <img
                    
                    className="slider_img slider_img_fade_in"
                    width={"100%"}
                    height={"100%"}
                    src={slides[currentSlide]}
                    alt="Slider was not found"
                />
            </div>
        </>
    );
};