import React, { useEffect, useRef } from "react";
import "./carousel.css"

export const Carousel: React.FC<{ list: any[], callback: (params: any, idx:number) => JSX.Element[] | JSX.Element }> = ({ list, callback }) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handleCurrentIndex = (action: "increment" | "decrement") => {
        if (action === 'increment') {
            setCurrentIndex(currentIndex === list.length - 1 ? 0 : currentIndex + 1);
        } else {
            setCurrentIndex(currentIndex === 0 ? list.length - 1 : currentIndex - 1);
        }
    }

    const handleTransitionEnd = () => {
        // This function is called when the transition animation ends
    };

    useEffect(() => {
        const firstChildOfContainer = document.querySelector('.carousel__container > *:first-child');
        const lastChildOfContainer = document.querySelector('.carousel__container > *:nth-child(2)');
        const middleChildOfContainer = document.querySelector('.carousel__container > *:first-child');

    }, [currentIndex]);

    
    const memoList = React.useMemo(() => {
        return [
            list[currentIndex === 0 ? list.length - 1 : currentIndex - 1],
            list[currentIndex],
            list[currentIndex === list.length - 1 ? 0 : currentIndex + 1],
        ]
    },[currentIndex, list])


    return (
        <div className="carousel">
            <div className="carousel__container">
                {memoList.map(callback)}
            </div>
            <div className="carousel__left_arrow" onClick={() => handleCurrentIndex("decrement")}></div>
            <div className="carousel__right_arrow" onClick={() => handleCurrentIndex("increment")}></div>
        </div>
    );
};