import React, { useMemo } from "react";
import "./discount_timer.css";
export const DiscountTimer: React.FC<{ list: string[] }> = ({ list }) => {
    let startPosition = false;
    return (
        <div id="discount_timer">
            <div id="discount_timer__wrapper">
                {list.filter(str => {
                    const [a, b] = str.split('');
                    if (a == '0' && b == '0' && startPosition == false) return false;
                    else {
                        startPosition = true;
                        return true;
                    }
                }).map((str, idx, arr) => {
                    const [a, b] = str.split('');
                    return (
                        <>
                            <div className="discount_timer_container">
                                <div>
                                    {a}
                                </div>
                                <div>
                                    {b}
                                </div>
                            </div>
                            {(arr.length > 1 && idx !== arr.length - 1) && <div className="discount_timer__space">:</div>}
                        </>
                    )
                })}
                {/* <div className="discount_timer_container">
                    <div>
                        1
                    </div>
                    <div>
                        2
                    </div>
                </div>
                <div className="discount_timer__space">:</div>
                <div className="discount_timer_container">
                    <div>
                        2
                    </div>
                    <div>
                        3
                    </div>
                </div> */}
            </div>
        </div>
    )
}